.signin_main_container {
    padding: 20px;
}

.signin_sub_container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(17, 16, 56, 1);
}

.signin_sub_container2 p {
    width: 70%;
    text-align: center;
    margin: 0px;
}

.signIn_logo img {
    min-width: 100px;
    width: 38%;
    margin: 60px 0px 30px 0px;
}

.addBusinessForm button.MuiButton-root,
.selectServiceCategoryForm button.MuiButton-root,
.selectSubServiceCategoryForm button.MuiButton-root,
.uploadLicenseForm button.MuiButton-root {
    margin-top: 16px;
}

.signin_main_container .formControl.steps input {
    margin-left: 0;
    padding-left: 52px;
}

.signin_main_container .formControl .MuiInputBase-root.MuiOutlinedInput-root {
    padding-left: 0;
    position: relative;
}

.signin_main_container .formControl .MuiInputBase-root.MuiOutlinedInput-root .MuiInputAdornment-root {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    max-height: unset;
}

.signin_main_container .formControl .MuiInputBase-root.MuiOutlinedInput-root .MuiInputAdornment-root img {
    width: 35px;
    height: 35px;
    box-shadow: 0px 4px 24px 0px #6879B41A;
}

@media only screen and (max-width: 1396px) {

    .personalDetailsForm .formControl.steps .MuiFormLabel-root,
    .addBusinessDetailsForm .formControl.steps .MuiFormLabel-root {
        font-size: 12px;
    }

    .personalDetailsForm .formControl.steps input,
    .addBusinessDetailsForm .formControl.steps input::placeholder {
        font-size: 12px !important;
    }

    .addBusinessDetailsForm .formControl textarea {
        font-size: 12px;
    }

    .addBusinessDetailsForm .formControl textarea::placeholder {
        font-size: 12px !important;
    }

    .addBusinessDetailsForm .formControl .MuiInputBase-root.MuiOutlinedInput-root {
        padding-top: 0;
    }

    .personalDetailsForm .formControl.steps input,
    .addBusinessDetailsForm .formControl.steps input {
        font-size: 12px;
        padding: 14px 18px 14px 13px;
    }

    .addBusinessForm .formControl.steps input,
    .selectServiceCategoryForm .formControl.steps input,
    .selectSubServiceCategoryForm .formControl.steps input {
        padding: 11px 14px;
    }

    .addBusinessForm .formControl img,
    .selectServiceCategoryForm .formControl img,
    .selectSubServiceCategoryForm .formControl img {
        width: 18px;
    }
}

@media only screen and (max-width: 1396px) {

    .selectServiceCategoryForm button.MuiButton-root,
    .selectSubServiceCategoryForm button.MuiButton-root,
    .uploadLicenseForm button.MuiButton-root {
        margin-top: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .signin_main_container .formControl {
        margin-bottom: 10px;
    }

    .addOperatingHours button.MuiButton-root,
    .uploadLicenseForm button.MuiButton-root {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .signin_main_container .formControl .MuiInputBase-root.MuiOutlinedInput-root .MuiInputAdornment-root img {
        width: 30px;
        height: 30px;
    }
}