.dashboardMenu .activeNav .activeIcon {
  display: block;
}

.dashboardMenu .activeNav .inactive {
  display: none;
}

.dashboardMenu .activeIcon {
  display: none;
}

.dashboardMenu .inactive {
  display: block;
}

.dashboardMenu .activeNav svg path {
  fill: #1cb469;
  fill-opacity: 1;
}

.addBusiness_search {
  margin: auto;
  display: flex;
  justify-content: center;
  background: #8989a30d;
  position: relative;
}

.addBusiness_search input,
.addBusiness_search select {
  width: 100%;
  background: #8989a30d;
  font-family: "Montserrat", sans-serif;
  padding: 15px;
  border-radius: 12px;
  border: 0.6px solid #8989a3;
  font-size: 18px;
}

.addBusiness_search input::placeholder {
  color: #8989a3;
  font-size: 16px;
  line-height: normal;
}

.addBusiness_search img {
  position: absolute;
  top: 15px;
  right: 25px;
}
