.tabbing-section .MuiButtonBase-root.MuiTab-root {
  color: #0a0032;
  font-size: 16px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  padding: 10px 60px;
  border-radius: 28px;
  min-height: 56px;
  font-weight: 600;
  height: 56px;
}

.have-questions {
  color: #111013;
}

.tabbing-section .MuiButtonBase-root.MuiTab-root.Mui-selected {
  background: #06038d;
  color: #fff;
  border: 0;
  border-radius: 28px;
}

.tabbing-section .MuiTabs-flexContainer {
  background: #06038d1a;
  border-radius: 28px;
  width: max-content;
}

.MuiPaper-root.MuiPaper-elevation.MuiAccordion-root::before {
  content: "";
  background: transparent;
  border: 0;
}

.tabbing-section .MuiBox-root {
  padding-left: 0;
  padding-right: 0;
  font-family: "Montserrat", sans-serif;
}

.tabbing-section
  .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded:last-child {
  border-bottom: 0;
}

.tabbing-section .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded {
  border-bottom: 1px solid #dedede;
}

.tabbing-section .MuiTabs-indicator {
  background: transparent;
}

.tabbing-section
  .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters {
  color: #06038d;
  margin: 10px 0;
  font-weight: 500;
}

.tabbing-section
  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
  color: #0d0639;
  opacity: 0.7;
  padding: 10px 0;
  font-family: "Montserrat", sans-serif;
}

.inputFormControl .MuiFormControl-root {
  width: 90%;
}

.inputFormControl .MuiFormControl-root fieldset {
  border-radius: 28px;
  border: 0;
}

.inputFormControl .MuiFormControl-root input {
  color: #0d0639;
  background: #f1f1ff;
  padding-left: 30px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  border-radius: 28px;
}

.inputFormControl .MuiFormControl-root input::placeholder {
  color: #a3a3a3;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 767px) {
  .inputFormControl .MuiFormControl-root {
    width: 80%;
  }

  .tabbing-section .MuiButtonBase-root.MuiTab-root {
    padding: 10px 30px;
    min-width: 100%;
  }

  .tabbing-section .MuiTabs-flexContainer {
    flex-direction: column;
    width: 100%;
  }

  /* Adding this */
  .tabbing-section
    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
    padding-right: 10px;
    font-size: 16px;
  }
}
