.formControl {
  margin-bottom: 24px;
  position: relative;
}

.formControl .inputLable {
  color: #0d0639;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.MuiFormHelperText-root.Mui-error {
  font-family: "Montserrat", sans-serif;
}

.MuiDialog-root.MuiModal-root {
  z-index: 999;
}

.formControl fieldset {
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  /* border: 1px solid #CFDBD599; */
}

.formControl input {
  font-family: "Montserrat", sans-serif;
  background: #f6f7f9;
  padding: 20px 14px;
  color: #0d0639;
  font-weight: 500;
}

.formControl input::placeholder,
.formControl textarea::placeholder {
  color: #757575;
  opacity: 1;
  font-weight: 400;
}

.formControl .MuiInputBase-root.MuiOutlinedInput-root {
  background: #f6f7f9;
}

.formControl textarea {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-weight: 500;
  padding-right: 10px;
}

.formControl .MuiTextField-root {
  width: 100%;
}

.formControl .MuiOutlinedInput-root.Mui-focused fieldset {
  border: 1px solid #cfdbd599;
}

.button button:disabled {
  background: #b3b3cf;
  color: #fff;
  box-shadow: none;
}

.button button {
  padding: 18px 82px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  font-weight: 600;
  border: 0;
  background: #06038d;
  font-size: 18px;
  border-radius: 12px;
  color: #fff;
  box-shadow: 4px 8px 24px 0px #3f8cff40;
}

.about_form p {
  text-align: center;
  margin-top: 0px;
}

.form_heading1 {
  text-align: center;
  /* font-size: 24px; */
  font-size: 1.6vw;
  font-weight: 600;
  margin: 1.6vw 0px;
}

.form_heading2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-size: 20px; */
  font-size: 1.45vw;
  font-weight: 600;
  margin: 10px;
}

.form_heading2 img {
  /* width: 40px; */
  width: 2.6vw;
  height: 2.6vw;
}

.form_heading2 label {
  font-size: 1.34vw;
  text-align: center;
}

.form_heading2 span {
  font-size: 16px;
  color: gray;
}

.skipBtn {
  font-size: 1.2vw !important;
  color: gray;
  cursor: pointer;
}

.errorText {
  color: red;
  font-family: Montserrat, sans-serif;
}

.deleteIcon {
  cursor: pointer;
}

form textarea {
  height: 100px;
}

.about_need_info {
  margin-top: 5.5vw;
}

.about_need_info img {
  width: 100%;
  height: 100%;
}

.form_col {
  width: 48%;
}

.form_img_txt {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}

.form_img_txt img {
  width: 1.6vw;
  height: 1.6vw;
  margin-right: 1.2vw;
}

.form_file_wrapper {
  text-align: center;
}

.form_file_block1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 1vw;
  margin: 1.7vw 0px 1vw 0px;
  border: 3px dotted var(--Primary-Color, rgba(6, 3, 141, 1));
  background: rgba(6, 3, 141, 0.05);
  border-radius: 12px;
  cursor: pointer;
}

.form_file_block1 img {
  width: 4.5vw;
  height: 4.5vw;
}

.form_file_block1 h4,
.form_file_block1 p {
  margin: 0.4vw;
}

.form_file_block1 h4 {
  font-size: 1vw;
}

.form_file_block1 p,
.uploadfile_note {
  font-size: 1vw;
  color: rgba(137, 137, 163, 1);
}

.form_heading3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  margin: 25px 0px;
}

.mainFileSelector {
  display: none;
}

.fileItem {
  border: 1px solid rgb(230, 224, 224);
  border-radius: 1.2vw;
  margin: 1vw 0px;
  padding: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fileItem_img {
  width: 48px;
  height: 48px;
}

.fileItem_del_img {
  width: 1.8vw;
  height: 1.8vw;
}

.fileItem_file {
  width: 100%;
  padding: 1vw;
  text-align: start;
}

.file_line {
  width: 100%;
  height: 0.2vw;
  background-color: black;
  border-radius: 7px;
}

.file_percent {
  font-size: 0.8vw;
  color: gray;
}

.formComplete {
  width: 80%;
  margin: auto;
}

.formComplete img {
  width: 11vw;
  height: 11vw;
}

.formComplete h2 {
  font-size: 1.6vw;
}

.formComplete p {
  font-size: 1.33vw;
  color: rgba(137, 137, 163, 1);
}

.setuploadfile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#start_icon {
  width: 1.4vw;
  height: 1.4vw;
  margin: 0px 0.6vw 0px 0px;
}

@media only screen and (max-width: 1536px) {
  .formControl input {
    padding: 16px 14px;
  }

  .formControl.steps input {
    font-size: 14px;
  }

  .formControl input::placeholder,
  .formControl textarea::placeholder {
    font-size: 14px;
  }

  .formControl .inputLable {
    font-size: 14px;
  }
}

@media only screen and (max-width: 786px) {
  .headerLogo {
    width: 40%;
  }

  .formControl {
    margin-bottom: 16px;
  }

  .button button {
    padding: 14px 42px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 520px) {
  .headerLogo {
    width: 70%;
  }

  .vectorImage {
    width: 100%;
  }

  .formControl input {
    padding: 14px 20px;
  }
}
