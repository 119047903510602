.MuiPaper-root.dropdownMenu {
  margin-top: 10px;
  border-radius: 30px;
  background: #fff;
  box-shadow: none;
  box-shadow: 0px 0px 20px 0px #00000012;
  border: none;
  font-family: 'Montserrat', Sans-serif;
  color: #021d34;
  padding: 0;
  /* max-height: 200px; */
  overflow: hidden;
  box-sizing: border-box;
}

.selectDropdown .selectPlaceholder {
  font-family: 'Montserrat', Sans-serif;
  color: #0d0639;
  font-size: 16px;
  font-weight: 400;
}

.selectDropdown fieldset {
  border: none;
}

.selectDropdown.search .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  position: absolute;
  right: 0;
}

.selectDropdown.search .MuiInputBase-input {
  z-index: 999;
}

.selectCategory .downArrowIcon {
  position: absolute;
  right: 0;
}

.selectCategory .MuiInputBase-input {
  z-index: 999;
}

.selectDropdown .MuiSelect-select {
  font-family: 'Montserrat', Sans-serif;
  color: #0d0639;
  font-weight: 500;
}

.MuiPaper-root.dropdownMenu ul::-webkit-scrollbar {
  display: none;
}

.MuiPaper-root.dropdownMenu::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  pointer-events: none;
}

.MuiPaper-root.dropdownMenu ul::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
}

.MuiPaper-root.dropdownMenu ul::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 1);
}

.MuiPaper-root.dropdownMenu ul {
  scrollbar-width: thin;
  scrollbar-color: rgb(255, 255, 255, 0.8) rgba(255, 255, 255, 0.2);
  padding: 15px;
  overflow-y: scroll;
  margin: 0;
}

.MuiPaper-root.dropdownMenu ul li {
  font-family: 'Montserrat', Sans-serif;
  font-size: 1rem;
  border-bottom: 1px solid #007ae4;
  padding: 20px 10px;
  white-space: normal;
  word-wrap: break-word;
  color: #021d34;
}

.MuiPaper-root.dropdownMenu ul li.Mui-selected,
.MuiPaper-root.dropdownMenu ul li:hover {
  background: #06038d;
  color: #fff;
}

.selectDropdown.search input::placeholder {
  font-family: 'Montserrat', Sans-serif;

  color: #021d34;
  font-size: 18px;
  opacity: 1;
}

.selectDropdown.search .MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 0;
}

.selectDropdown.search .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
  padding-right: 20px;
}

.selectDropdown.search .MuiAutocomplete-input {
  font-family: 'Montserrat', Sans-serif;
  color: #0d0639;
  font-size: 1rem;
  font-weight: 500;
}

.selectDropdown.search .MuiAutocomplete-input::placeholder {
  font-weight: 400;
}

.MuiAutocomplete-popper ul {
  padding: 15px;
}

.MuiAutocomplete-popper ul li {
  font-family: 'Montserrat', Sans-serif;
  font-size: 1rem;
  border-bottom: 1px solid #007ae4;
  padding: 20px 10px !important;
  white-space: normal;
  word-wrap: break-word;
  color: #021d34;
}

.MuiAutocomplete-popper ul li:last-child {
  border: 0;
}

.MuiAutocomplete-popper ul li:hover,
.MuiAutocomplete-popper ul li.Mui-selected {
  background: #06038d !important;
  color: #fff !important;
}

.MuiAutocomplete-popper {
  margin-top: 10px;
  border-radius: 30px;
  background: #fff;
  box-shadow: none;
  box-shadow: 0px 0px 20px 0px #00000012;
  border: none;
  font-family: 'Montserrat', Sans-serif;
  color: #021d34;
  padding: 0;
  /* max-height: 200px; */
  overflow: hidden;
  box-sizing: border-box;
}

.ZipCodeSearch {
  width: 100%;
  position: relative;
}

/* .selectDropdown .selectPlaceholder {
  font-weight: 500;
} */

@media only screen and (max-width: 1280px) {

  .MuiPaper-root.dropdownMenu ul li,
  .selectDropdown .selectPlaceholder,
  .selectDropdown.search input::placeholder {
    font-size: 14px;
    font-weight: 500;
  }

  .selectDropdown.search .MuiAutocomplete-input,
  .selectDropdown.search .MuiAutocomplete-input::placeholder {
    font-size: 14px;
  }

  .selectDropdown.search .MuiInputAdornment-root img {
    width: 16px;
  }

  .selectDropdown.search .notranslate+img {
    width: 22px;
  }

  .selectDropdown input+img,
  .selectDropdown .selectPlaceholder+img {
    width: 16px;
  }

  .selectDropdown .MuiSelect-select {
    font-size: 14px;
  }

  .ZipCodeSearch .formControl input {
    padding: 14px;
  }

  /* .ZipCodeSearch .MuiButtonBase-root img {
    width: 18px;
  } */
}