.testimonialsSlider .slick-prev,
.testimonialsSlider .slick-next {
  box-shadow: 0px 4px 26px 0px #00000029;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.testimonialsSlider .slick-prev:hover,
.testimonialsSlider .slick-prev:focus,
.testimonialsSlider .slick-next:hover,
.testimonialsSlider .slick-next:focus {
  background: #fff;
}

.testimonialsSlider.slick-slider {
  position: static;
}

.testimonialsSlider .slick-prev:before {
  content: "";
  background-image: url(../../../../assets/Consumer/left-arrow.svg);
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-size: contain;
}

.testimonialsSlider .slick-next:before {
  content: "";
  background-image: url(../../../../assets/Consumer/next-arrow.svg);
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-size: contain;
}

@media only screen and (max-width: 1024px) {
  .testimonialsSlider .slick-next {
    right: -10px;
  }

  .testimonialsSlider .slick-prev {
    left: -10px;
  }

}