@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  width: 100vw;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
}

hr {
  border: 1px solid rgb(239, 233, 233);
}

.calendar-container {
  background: rgba(242, 247, 253, 1);
  border-radius: 12px;
}

.day {
  width: 3.5vw !important;
  height: 3.5vw;
  padding: 0px !important;
  border: none !important;
  border-radius: 9999px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected {
  background-color: #06038d !important;
}

.day-name {
  font-size: 10px !important;
}

.date {
  font-size: 18px !important;
}

.selected .day-name {
  color: white;
}

.calendar-header {
  justify-content: center !important;
}

.month-label {
  margin: 0px 20px;
}

.nav-button {
  background-color: transparent !important;
  color: black !important;
  font-size: 22px !important;
}

a {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

input[type="checkbox"] {
  accent-color: rgba(6, 3, 141, 1);
  width: 20px;
  height: 20px;
}

.headerMenu .activeNav .MuiTypography-root.MuiLink-root {
  font-weight: 600;
  position: relative;
}

.headerMenu .activeNav .MuiTypography-root.MuiLink-root::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: #1cb469;
  width: 22px;
  display: block;
  bottom: -5px;
}

.nav-link-about-us {
  font-size: 16px;
  color: #070477;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  opacity: 0.6;
  text-decoration: none;
}
