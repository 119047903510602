.breadCums-icon {
  padding-left: 14px;
}

.categoryTitle {
  width: 100%;
  border-radius: 50%;
}

.astrikIcon {
  margin-bottom: 5px;
}

.errorText {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.MuiInputBase-input .MuiOutlined-Input-input .Mui-disabled .disabled {
  font-size: 14px;
  font-weight: bold;
}
