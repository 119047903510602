.settingTabs .MuiButtonBase-root.MuiTab-root {
  text-align: left;
  align-items: flex-start;
  justify-content: start;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-weight: 400;
  min-height: unset;
  padding: 0 0 28px;
  color: #6a6686;
}

.settingTabs .MuiButtonBase-root.MuiTab-root .MuiTab-iconWrapper {
  display: flex;
}

.settingTabs .MuiButtonBase-root.MuiTab-root svg {
  margin-right: 20px;
  height: 24px;
  width: 24px;
}

.settingTabs .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #06038d;
  font-weight: 500;
}

.settingTabs .icon img {
  margin-right: 12px;
  height: 24px;
  width: 24px;
}

.settingTabs .MuiButtonBase-root.MuiTab-root.Mui-selected img {
  display: none;
}

.settingTabs .MuiButtonBase-root.MuiTab-root.Mui-selected .active {
  display: block;
}

.settingTabs .active {
  display: none;
}

.settingTabs .MuiTabs-indicator {
  background: transparent;
}

.settingTabs .MuiTabs-root.MuiTabs-vertical {
  border-color: #8989a3;
  height: 80%;
  min-width: 300px;
}

.settingsPage .formControl input {
  font-weight: 400;
  background: #fff;
  border-radius: 12px;
  /* border: 1px solid #CFDBD599; */
  padding: 20px 50px 20px 20px;
}

.settingsPage .MuiInputBase-root.MuiOutlinedInput-root {
  background: #fff;
  padding-right: 48px;
  border-radius: 12px;
}

.settingsPage .formControl.zipcode .MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 0;
}

.settingsPage .formControl textarea {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.settingsPage .formControl fieldset {
  border: 1px solid #cfdbd599;
  border-radius: 12px;
}

.settingsPage .editIcon {
  position: absolute;
  right: 20px;
  bottom: 16px;
  cursor: pointer;
}

.settingsPage .passwordIcon {
  bottom: 20px;
  cursor: pointer;
}

.settingsPage .passwordIconError {
  bottom: 38px !important;
}
.dashboardPage{
  display:flex;
}
.settingTabs::-webkit-scrollbar,
.dashboardPage ::-webkit-scrollbar {
  width: 0;
}

.settingTabs::-webkit-scrollbar-track,
.dashboardPage::-webkit-scrollbar-track {
  background: transparent;
}

.settingTabs::-webkit-scrollbar-thumb,
.dashboardPage::-webkit-scrollbar-thumb {
  background: transparent;
}

.settingTabs::-webkit-scrollbar-thumb:hover,
.dashboardPage::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.settingsPage .errorWrapper .editIcon {
  bottom: 30px;
}

.settingsPage .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  /* padding-right: 0; */
}

.settingsPage .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled fieldset {
  opacity: 0.6;
  background: #e3e6e9;
}

.settingsPage .formControl input.Mui-disabled,
.settingsPage .formControl textarea.Mui-disabled {
  -webkit-text-fill-color: #0d0639;
  padding-right: 0;
  font-weight: 600;
}

.contentPage h1,
.contentPage h2,
.contentPage h3,
.contentPage h4,
.contentPage h5,
.contentPage h6 {
  /* color: #0d0639; */
}

.contentPage blockquote {
  margin: 0;
}

.contentPage li {
  color: #8989a3;
}

.contentPage a {
  color: #050267;
}

.contentPage ol,
.contentPage ul {
  padding-left: 20px;
}

.formControl textarea::-webkit-scrollbar {
  width: 5px;
}

.formControl textarea::-webkit-scrollbar-track {
  background: #000102;
  border-radius: 5px;
}

.formControl textarea::-webkit-scrollbar-thumb {
  background: #756b6b;
  border-radius: 5px;
}

@supports not selector(::-webkit-scrollbar) {
  .formControl textarea {
    scrollbar-color: #756b6b #000102;
  }
}

.settingsPage .description.editIcon {
  bottom: unset;
  top: 47px;
  right: 10px;
}

@media only screen and (max-width: 1536px) {
  .settingTabs .MuiButtonBase-root.MuiTab-root {
    font-size: 18px;
  }

  .settingsPage .formControl input {
    padding: 18px 50px 18px 20px;
    font-size: 14px;
  }

  .settingsPage .formControl textarea {
    font-size: 14px;
  }

  .settingsPage .editIcon {
    bottom: 10px;
  }

  .settingsPage .editIcon img {
    width: 20px;
  }

  .settingsPage .boxEditIcon {
    width: 20px;
  }

  .settingsPage .passwordIcon {
    bottom: 16px;
  }
}

@media only screen and (max-width: 1024px) {
  .addBusinessFormWrapper {
    width: 80% !important;
  }
  .settingTabs .MuiTabs-root.MuiTabs-vertical {
    height: 100%;
    min-width: 250px;
  }
}

@media only screen and (max-width: 1280px) {
  .settingTabs .MuiButtonBase-root.MuiTab-root {
    padding: 0 0 25px;
  }

  .settingsPage .formControl input {
    padding: 15px 50px 15px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .settingTabs .MuiButtonBase-root.MuiTab-root {
    font-size: 0;
    min-width: auto;
    padding: 0 0 20px 0;
  }

  .settingsPage .formControl input {
    padding: 15px 35px 15px 10px;
  }

  .settingsPage .formControl input::placeholder {
    font-size: 12px;
  }

  .settingTabs .MuiTabs-root.MuiTabs-vertical {
    min-width: auto;
  }

  .settingTabs .MuiTypography-root.MuiTypography-body1 {
    width: 100%;
  }

  .settingTabs .icon img {
    margin-right: 0;
  }

  .settingsPage .descriptionInput .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 40px;
  }

  .settingsPage .editIcon {
    right: 10px;
  }

  .addBusinessFormWrapper {
    width: 90% !important;
  }

  .contentPage h1,
  .contentPage h2,
  .contentPage h3,
  .contentPage h4,
  .contentPage h5,
  .contentPage h6 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .contentPage p {
    margin: 10px 0;
  }

  .contentPage ol,
  .contentPage ul {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .settingTabs .MuiTabs-root.MuiTabs-vertical .MuiTabs-flexContainerVertical {
    flex-direction: row;
  }

  .settingTabs .MuiTabs-root.MuiTabs-vertical .MuiTab-iconWrapper {
    margin-right: 20px;
  }

  .settingTabs .icon img {
    height: 30px;
    width: 30px;
  }

  .settingTabs .deleteAccountBox {
    padding-left: 9px;
  }

  .settingTabs .deleteAccountBox .MuiBox-root img {
    height: 26px;
    width: 26px;
  }
}

@media only screen and (max-width: 426px) {
  .settingsPage .errorWrapper .editIcon {
    bottom: 49px;
  }
}