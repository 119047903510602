.formBox {
    box-shadow: 0px 69px 42px 0px #C8C8C840;
    border-radius: 20px;
    position: "relative";
    padding: 50px 0;
    background: #fff;
    min-height: 30vh;
    width: 60%;
    justify-self: center;
    margin-top: 8%;
  }